



















import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
    name: "SearchbarTable",
    emits: ['update', 'reset'],
    setup(props, { emit }) {
        const filter = ref('')

        const resetFilter = () => {
            filter.value = ''
            emit('update', filter.value)
            emit('reset')
        }

        return {
            filter,
            resetFilter
        }
    }
})
