


























import { defineComponent } from "@vue/composition-api";
import { useTable } from "@/composables/useTable";

export default defineComponent({
    name: "PaginationRow",
    props: {
        rowClass: String,
        perPage: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        },
        amountRows: {
            type: Number,
            required: true
        }
    },
    setup() {
        const { pageOptions } = useTable()

        return {
            pageOptions
        }
    }
})
