

























































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { CostFactor } from "@/types/apiEntities";
import EditCostFactor from "@/components/Modal/EditCostFactor.vue";
import AddCostFactor from "@/components/Modal/AddCostFactor.vue";
import { useTable } from "@/composables/useTable";
import { modalController, useModal } from "@/composables/useModal";
import PaginationRow from "@/components/PaginationRow.vue";
import { TableCostFactor } from "@/types/tableTypes";
import { useCostFactorAPI } from "@/api-connectors/useCostFactorAPI";
import { toastController } from "@/composables/useToast";
import SearchbarTable from "@/components/Table/SearchbarTable.vue";

export default defineComponent({
    name: "CostFactor",
    components: { SearchbarTable, PaginationRow, AddCostFactor, EditCostFactor },
    setup() {
        const { deleteCostFactorRequest } = useCostFactorAPI()

        const { updateTable, pageOptions } = useTable()
        const { createMsgBoxConfirm } = useModal()

        const items = reactive<CostFactor[]>([])
        const fields = [
            { key: 'areaType', label: 'L3-Bereich', sortable: true },
            { key: 'price', label: 'Preis', sortable: true },
            { key: 'editId', label: '', thClass: 'button-col' }
        ]

        const perPage = ref(10);
        const currentPage = ref(1);
        const amountRows = ref(0);
        const isBusy = ref(false)
        const clickedID = ref('');
        const filter = ref('');
        const sortBy = ref('areaType');
        const sortDesc = ref(false);

        const updateCostFactor = async (page?: number) => {
            isBusy.value = true
            if (!page) page = currentPage.value;
            const offset = (page - 1) * perPage.value
            await updateTable({
                routePath: 'costFactor',
                targetList: items,
                targetMaxCount: amountRows,
                offset: offset,
                perPage: perPage.value,
                filter: filter.value,
                convertFunction: addIdCol
            })
            isBusy.value = false
        }

        const editCostFactor = (areaType: string) => {
            clickedID.value = areaType ?? '';
            modalController.show('edit-cost-factor')
        }

        const deleteCostFactor = async (areaType: string) => {
            console.log('delete costfactor', areaType)
            const response = await deleteCostFactorRequest(areaType)

            if (!response) return toastController.toast(`Der Kostenfaktor konnte nicht gelöscht werden`, {
                title: 'Kostenfaktor nicht gelöscht',
                autoHideDelay: 3000,
                variant: 'danger',
                solid: true
            })

            toastController.toast(`Der Kostenfaktor wurde gelöscht`, {
                title: 'Kostenfaktor gelöscht',
                autoHideDelay: 3000,
                variant: 'success',
                solid: true
            })

            await updateCostFactor()
        }

        const checkRemoveCostFactor = (areaType: string) => {
            createMsgBoxConfirm({
                header: 'Löschen',
                contentText: 'Wollen Sie diesen Kostenfaktor wirklich löschen?',
                okHandler: () => deleteCostFactor(areaType),
            })
        }

        const addIdCol = (list: TableCostFactor[]): TableCostFactor[] => {
            for (const costFactor of list) {
                costFactor.editId = costFactor.areaType
            }
            return list;
        }

        const showAddCostFactor = (areaType: string) => {
            console.log(areaType)
            modalController.show('add-cost-factor')
        }

        onMounted(() => {
            isBusy.value = true
            updateCostFactor(1)
        })

        return {
            items,
            currentPage,
            perPage,
            amountRows,
            fields,
            pageOptions,
            isBusy,
            clickedID,
            filter,
            sortBy,
            sortDesc,
            updateCostFactor,
            editCostFactor,
            showAddCostFactor,
            checkRemoveCostFactor
        }
    }
})
