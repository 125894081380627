import { AxiosResponse } from "axios";
import { useAPI } from "@/composables/useAPI";
import { CostFactor } from "@/types/apiEntities";

export function useCostFactorAPI() {
    const { api } = useAPI()

    const getCostFactorsRequest = async (): Promise<AxiosResponse | undefined> => {
        return await api.get('costFactor/')
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const getCostFactorRequest = async (areaType: string): Promise<AxiosResponse | undefined> => {
        return await api.get('costFactor/' + areaType)
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const editCostFactorRequest = async (areaType: string, costFactor: CostFactor): Promise<AxiosResponse | undefined> => {
        return await api.put('costFactor/update/' + areaType, costFactor)
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const addCostFactorRequest = async (costFactor: CostFactor): Promise<AxiosResponse | undefined> => {
        return await api.post('costFactor/create', costFactor)
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const deleteCostFactorRequest = async (areaType: string): Promise<AxiosResponse | undefined> => {
        return await api.delete('costFactor/delete/' + areaType)
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const getAllCostFactors = async (): Promise<AxiosResponse | undefined> => {
        return await api.get('costFactor/all')
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    return {
        getCostFactorsRequest,
        getCostFactorRequest,
        editCostFactorRequest,
        addCostFactorRequest,
        deleteCostFactorRequest,
        getAllCostFactors
    }
}