












import { defineComponent } from "@vue/composition-api";

export default defineComponent({
    name: "FormInput",
    props: {
        label: String,
        value: String,
        type: {
            type: String,
            default: 'text'
        },
        required: Boolean
    }
})
