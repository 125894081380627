













import { defineComponent, reactive, watch } from "@vue/composition-api";
import FormCostFactor from "@/components/Modal/Subcomponents/FormCostFactor.vue";
import { CostFactor } from "@/types/apiEntities";
import { useCostFactorAPI } from "@/api-connectors/useCostFactorAPI";
import { toastController } from "@/composables/useToast";
import { modalController } from "@/composables/useModal";

export default defineComponent({
    name: "EditCostFactor",
    components: { FormCostFactor },
    props: {
        costFactorId: String
    },
    setup(props, { emit }) {
        const { getCostFactorRequest, editCostFactorRequest } = useCostFactorAPI()

        const costFactor = reactive<CostFactor>({ areaType: '', price: '' } as CostFactor)

        const checkValues = async (): Promise<boolean> => {
            console.log('checking')
            if (Number(costFactor.price) < 0) {
                toastController.toast('Bitte wählen Sie einen positiven Preis', {
                    title: 'Preis negativ',
                    autoHideDelay: 3000,
                    variant: 'danger',
                    solid: true
                })
                return false
            }

            return true
        }

        const editCostFactor = async () => {
            if (!(await checkValues())) return;

            if (!props.costFactorId) return;
            const response = await editCostFactorRequest(props.costFactorId, costFactor)

            if (!response) return;

            toastController.toast('Der Kostenfaktor wurde bearbeitet', {
                title: 'Kostenfaktor bearbeitet',
                autoHideDelay: 3000,
                variant: 'success',
                solid: true
            })
            emit('update-table')

            modalController.hide('add-cost-factor')
        }

        const updateCostFactor = (updatedCostFactor: CostFactor) => {
            Object.assign(costFactor, updatedCostFactor)
        }

        const clearTagForm = () => {
            Object.assign(costFactor, { areaType: '', price: '' } as CostFactor)
            emit('reset-id')
        }

        const getCurrentCostFactor = async () => {
            if (!props.costFactorId) return;
            const response = await getCostFactorRequest(props.costFactorId)

            if (!response) return;
            Object.assign(costFactor, response.data)
        }

        watch(props, () => getCurrentCostFactor())

        return {
            costFactor,
            clearTagForm,
            editCostFactor,
            updateCostFactor
        }
    }
})
