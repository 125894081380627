import vueInstance from "@/main";

export const modalController = vueInstance.$bvModal

export function useModal() {

    const createMsgBoxConfirm = (creationParams: { header: string, contentText: string, okHandler: Function, cancelHandler?: Function }) => {
        const { header, contentText, okHandler, cancelHandler } = creationParams

        modalController?.msgBoxConfirm(contentText, {
            title: header,
            size: 'md',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'Ja',
            cancelTitle: 'Nein',
            centered: true
        }).then(value => {
            if (value) return okHandler();

            if (cancelHandler) cancelHandler();
        })
    }

    return {
        createMsgBoxConfirm
    }
}