













import { defineComponent, reactive } from "@vue/composition-api";
import { useCostFactorAPI } from "@/api-connectors/useCostFactorAPI";
import { CostFactor } from "@/types/apiEntities";
import { toastController } from "@/composables/useToast";
import { modalController } from "@/composables/useModal";
import FormCostFactor from "@/components/Modal/Subcomponents/FormCostFactor.vue";

export default defineComponent({
    name: "AddCostFactor",
    components: { FormCostFactor },
    props: {
        costFactorId: String
    },
    setup(props, { emit }) {
        const { addCostFactorRequest } = useCostFactorAPI()

        const costFactor = reactive<CostFactor>({ areaType: '', price: '' } as CostFactor)

        const checkValues = async (): Promise<boolean> => {
            if (Number(costFactor.price) < 0) {
                toastController.toast('Bitte wählen Sie einen positiven Preis', {
                    title: 'Preis negativ',
                    autoHideDelay: 3000,
                    variant: 'danger',
                    solid: true
                })
                return false
            }

            return true
        }

        const addCostFactor = async () => {
            if (!(await checkValues())) return

            const response = await addCostFactorRequest(costFactor)

            if (!response) return;

            toastController.toast('Der Kostenfaktor wurde hinzugefügt', {
                title: 'Kostenfaktor hinzugefügt',
                autoHideDelay: 3000,
                variant: 'success',
                solid: true
            })
            emit('update-table')

            modalController.hide('edit-cost-factor')
        }

        const updateCostFactor = (updatedCostFactor: CostFactor) => {
            Object.assign(costFactor, updatedCostFactor)
        }

        const clearCostFactor = () => {

        }

        return {
            costFactor,
            addCostFactor,
            clearCostFactor,
            updateCostFactor
        }
    }
})
