
























































import { defineComponent, PropType, reactive } from "@vue/composition-api";
import FormInput from "@/components/FormFields/FormInput.vue";
import { CostFactor } from "@/types/apiEntities";

export default defineComponent({
    name: "FormCostFactor",
    components: { FormInput },
    props: {
        costFactor: {
            type: Object as PropType<CostFactor>,
            required: true
        },
        edit: Boolean
    },
    setup(props) {

        const updatedCostFactor = reactive<CostFactor>(props.costFactor)
        // const tagChargers = reactive<{ id: number; name: string }[]>([])
        // const unassignedChargers = reactive<{ id: number; name: string }[]>([])
        //
        // const selectedCharger = ref<number | null>(null)
        //
        // const price = computed({
        //     get: () => {
        //         return Number(props.costFactor.price)
        //     },
        //     set: (value) => {
        //         updatedCostFactor.price = value.toString()
        //     }
        // })
        //
        // const updateTagChargers = () => {
        //     tagChargers.splice(0)
        //
        //     if (!updatedCostFactor.chargers) return tagChargers
        //
        //     for (const charger of updatedCostFactor.chargers) {
        //         tagChargers.push({ id: charger.id, name: charger.name })
        //     }
        // }
        //
        // const updateUnassignedChargers = async () => {
        //     // TODO: Connect with route that returns all Chargers with no Costfactor
        // }
        //
        // const updateChargers = () => {
        //     updateTagChargers()
        //     updateUnassignedChargers()
        // }
        //
        // const removeCharger = (chargerID: number) => {
        //     const index = tagChargers.findIndex(charger => charger.id === chargerID);
        //     console.log(index)
        //
        //     if (index === -1) return;
        //     const removedItems = tagChargers.splice(index, 1)
        //     console.log(removedItems)
        //
        //     for (const charger of removedItems) {
        //         console.log(charger)
        //         unassignedChargers.push(charger)
        //     }
        //     console.log(unassignedChargers)
        //     unassignedChargers.sort((a, b) => a.id > b.id)
        //     selectedCharger.value = null
        //
        //     updatedCostFactor.chargers.splice(index, 1)
        //     emit('update', updatedCostFactor)
        // }
        //
        // const addCharger = (chargerID: number) => {
        //     const index = unassignedChargers.findIndex(charger => charger.id === chargerID);
        //
        //     if (index === -1) return;
        //     const removedItems = unassignedChargers.splice(index, 1)
        //     for (const charger of removedItems) {
        //         tagChargers.push(charger)
        //     }
        //     tagChargers.sort((a, b) => a.id > b.id)
        //     selectedCharger.value = null
        //     updatedCostFactor.chargers.splice(index, 1)
        //     emit('update', updatedCostFactor)
        // }
        //
        // onMounted(() => updateChargers())

        return {
            updatedCostFactor,
            // tagChargers,
            // unassignedChargers,
            // selectedCharger,
            // removeCharger,
            // addCharger
        }
    }
})
